import * as yup from "yup";

let QuestionGroupValidationSchema = yup.object().shape({
    status: yup.boolean(),
    title: yup.string().required("Required !"),
    // DisplayNumber: yup
    //     .string()
    //     .oneOf(["1", "2", "3", "4", "5", "All"])
    //     .required("Required !"),
});

export default QuestionGroupValidationSchema;
