import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DOMPurify from "dompurify";
import DeleteModal from "../../common/modals/DeleteModal";
import moment from "moment";

const ViewPosts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [postDetailData, setPostDetailData] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (id) {
      getPostDetail();
    }
    // eslint-disable-next-line
  }, [id]);

  // Fetch User Data
  const getPostDetail = async () => {
    const { data, success } = await new APIServices(`post/${id}`).get();
    if (success) {
      setPostDetailData(data);
    }
  };

  const handleShowDeleteModal = () => {
    setShowModal(true);
  };

  const handleDeletePost = async () => {
    const { message, success } = await new APIServices(`post/${id}`).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/post");
    }
  };
  return (
    <section className="pb-16 pt-8 bg-white dark:bg-gray-800 ">
      <div className="max-w-4xl px-4 mx-auto">
        <button
          onClick={handleShowDeleteModal}
          className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
        >
          Delete
        </button>
        <h2 className="text-2xl font-semibold mb-1 font-poppins dark:text-gray-300">
          {postDetailData?.title}
        </h2>
        {postDetailData?.publishedOn && (
          <span className="mb-7 block text-sm text-gray-600">
            {" "}
            Published On :{" "}
            {moment(postDetailData?.publishedOn, moment.ISO_8601).format(
              "LLLL"
            )}
          </span>
        )}
        <div
          className="pt-5 pb-5  "
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(postDetailData.detail),
          }}
        />
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeletePost} close={setShowModal} />
      )}
    </section>
  );
};

export default ViewPosts;
