import * as yup from "yup";
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
  "image;base64",
  "image/svg+xml",
];
let PostValidationSchema = yup.object().shape({
  status: yup.boolean(),
  title: yup
    .string()
    .required("Required !"),
  slug: yup
    .string()
    .required("Required !"),
  pageType: yup.string().required("Required !"),
  featuredImage: yup
    .mixed()
    .notRequired()
    .nullable()
    .test("fileSize", "The file is too large", (value) => {
      if (!value) return true;
      return !value || (value && value.size <= 1024 * 1024 * 2);
    })
    .test(
      "fileFormat",
      "Unsupported File Format",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),

  publishedOn: yup.string(),
  nextReviewDate: yup.string(),
  tags: yup.array(),
  redirectTO: yup.string(),
  remark: yup.string(),
  isVerified: yup.boolean(),
  orderBy: yup.number(),
  detail: yup.string(),
  summary: yup.string(),
  seoTitle: yup.string(),
  seoDescription: yup.string(),
});

export default PostValidationSchema;
