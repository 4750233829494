import React, { useState, useEffect } from "react";
import APIServices from "../../../httpServices/httpServices";
import { useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";

const ViewSetting = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {
    if (id) {
      getSettingDetailView();
    }
    // eslint-disable-next-line
  }, [id]);

  // Fetch User Data
  const getSettingDetailView = async () => {
    const { data, success } = await new APIServices(`setting/${id}`).get();
    if (success) {
      setSettingData(data);
    }
  };

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteSetting = async () => {
    const { message, success } = await new APIServices(
      `setting/${id}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/setting");
    }
  };

  const { _id, title, uniqueName, status, settingValue } = settingData;

  return (
    <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
      <div className="flex justify-between mt-5 mb-4 items-end">
        <h2 className="font-bold text-lg"> Setting Information </h2>
        {status === "Inactive" && (
          <button
            onClick={() => handleShowDeleteModal(_id)}
            className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
          >
            Delete
          </button>
        )}
      </div>
      <div className="shadow bg-white border-gray-500 p-8 rounded">
        <div className="flex flex-wrap">
          <DataView label="title" value={title} />
          <DataView label="uniqueName" value={uniqueName} />
          <DataView label="status" value={status} />
          <div className="w-1/2">
            <label className="block mb-0 text-sm text-gray-600 font-normal">
              {" "}
              Setting Value{" "}
            </label>
            <div className="flex flex-wrap gap-2 items-center pt-2 font-medium">
              {settingValue &&
                JSON.parse(settingValue)?.map((val, idx) => {
                  return (
                    <span
                      key={idx}
                      className="inline-block px-2 text-xs py-1 text-blue-700 bg-blue-100 rounded dark:bg-gray-800 "
                    >
                      {val}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeleteSetting} close={setShowModal} />
      )}
    </div>
  );
};

export default ViewSetting;
