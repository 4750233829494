import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import ChartReport from "../../report/ChartReport";
import LoadingSkeleton from "../../common/skeleton/LoadingSkeleton";

const QuestionTypeReport = () => {
    const { QuestionGroupId } = useLocation().state;
    const { id } = useParams();
    const [questionData, setQuestionData] = useState({});
    const [questionReportData, setQuestionReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (id && questionData) {
            getQuestionTypeReport();
        }
        // eslint-disable-next-line
    }, [id, questionData]);

    const getDetailView = async () => {
        const { data, success } = await new APIServices(`question/${id}`).get();
        if (success) {
            setQuestionData(data);
        }
    };
    const getQuestionTypeReport = async () => {
        setIsLoading(true);
        try {
            const { data, success } = await new APIServices(`report/${QuestionGroupId}`).post({
                Type: questionData.Type
            });

            if (success) {
                setQuestionReportData(data);
            }
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> Question Type Report </h2>
            </div>
            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <ChartReport
                    questionReportData={questionReportData}
                />
            )}
        </div>
    );
};

export default QuestionTypeReport;
