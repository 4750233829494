import React, { useEffect, useState } from "react";

import CustomTable from "../../../common/CustomTable";
import APIServices from "../../../../httpServices/httpServices";
import DeleteModal from "../../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../../common/toast/ToastContainer";
import TablePagination from "../../../common/table/TablePagination";
import TableEntry from "../../../common/table/TableEntry";
import TableSkeleton from "../../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../../utilities/constant";

const NotFoundLogList = () => {
  const tableHeading = [
    "currentUrl",
    "fromUrl",
    "iP",
    "time",
    "status",
    "Action",
  ];
  const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = LIMIT
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfDataToShowOnTable();
      }, 1500);
    } else {
      getListOfDataToShowOnTable();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfDataToShowOnTable = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "logs/not-found/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setDataListToShowOnTable(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleShowDeleteModal = (id) => {
    setSelectedItemId(id);
    setShowModal(true);
  };

  const handleDelete = async () => {
    const { message, success } = await new APIServices(
      `log/not-found/${selectedItemId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfDataToShowOnTable();
    }
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title="List of 404 Logs"
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={dataListToShowOnTable}
                tableHeadingData={tableHeading}
                handleDelete={handleShowDeleteModal}
                handleEdit={false}
                handleView={false}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDelete} close={setShowModal} />
      )}
    </section>
  );
};

export default NotFoundLogList;
