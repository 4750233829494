import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import CodeMirrorDialog from './CodeMirrorDialog';
import './model.css';
import LoadingSkeleton from '../skeleton/LoadingSkeleton';

const TinyPassageEditor = ({ fieldname, initialValue, setFieldValue, label }) => {
    const editorRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editorContent, setEditorContent] = useState(initialValue ?? '');
    const [loading, setLoading] = useState(true); // Loading state

    // Highlight patterns immediately upon initialization
    useEffect(() => {
        if (initialValue) {
            setEditorContent(initialValue);
        }
        setLoading(false); // Hide loader after content is ready
    }, [initialValue]);


    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
        setFieldValue(fieldname, content); // Save the highlighted content back to Formik
    };
    const initEditor = (editor) => {
        editor.ui.registry.addButton('codeEditor', {
            text: 'Code Editor',
            onAction: () => {
                setEditorContent(editor.getContent());
                setIsModalOpen(true);
            },
        });
    };

    const handleSave = (updatedContent) => {
        if (editorRef.current) {
            editorRef.current.setContent(updatedContent);
        }
        setIsModalOpen(false);
    };


    const handleImageUpload = (blobInfo, progress) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const uploadUrl = process.env.REACT_APP_API_BASE_URL + "/api/file/upload";

            xhr.open("POST", uploadUrl, true);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const percent = (e.loaded / e.total) * 100;
                    progress(percent); // Report progress to TinyMCE
                }
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error 403: Forbidden", remove: true });
                    return;
                }
                if (xhr.status === 404) {
                    reject({ message: "HTTP Error 404: Not Found", remove: true });
                    return;
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location !== "string") {
                    reject("Invalid JSON response: " + xhr.responseText);
                    return;
                }

                // Resolve the image URL to insert in the editor
                resolve(json.location);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed due to a network error", remove: true });
            };

            xhr.send(formData);
        });
    };

    return (
        <>
            {loading ? (
                // Loader Component
                <LoadingSkeleton />
            ) : (
                <div className="grid grid-cols-2 gap-4">
                    {/* TinyMCE Editor */}
                    <h3 className="text-lg font-medium "> {label} </h3>
                    <div className="col-span-3">
                        <Editor
                            apiKey='mfubp5wh9q48o9avo78nk1nikio8st2dmqd13yjk2jn1o30y'
                            value={editorContent}
                            onEditorChange={handleEditorChange} // Handle content changes but don't save to Formik immediately
                            textareaName={fieldname}
                            init={{
                                plugins: [
                                    // Core editing features
                                    'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount', 'code',
                                ],
                                toolbar: 'undo redo blocks bold italic underline image media table | align | numlist bullist indent outdent  | codeEditor ', tinycomments_mode: 'embedded',
                                tinycomments_mode: 'embedded',
                                tinycomments_author: 'Author name',
                                mergetags_list: [
                                    { value: 'First.Name', title: 'First Name' },
                                    { value: 'Email', title: 'Email' },
                                ],
                                setup: initEditor,
                                images_upload_handler: (blobInfo, progress) => {
                                    return handleImageUpload(blobInfo, progress);
                                },
                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                            }}

                        />
                        <CodeMirrorDialog
                            content={editorContent}
                            isOpen={isModalOpen}
                            onSave={handleSave}
                            onClose={() => setIsModalOpen(false)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default TinyPassageEditor;
