import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../TextError";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const FormikDateTimePickerField = ({ label, name, formik }) => {
  const { t } = useTranslation();
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium "
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <input
              type="datetime-local"
              name={name}
              min={new Date().toISOString().slice(0, -8)}
              value={field.value || ""}
              onChange={(e) => form.setFieldValue(field.name, e.target.value)}
              onBlur={form.handleBlur}
              className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${meta.touched && meta.error ? "border-red-600" : null
                }`}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikDateTimePickerField;
