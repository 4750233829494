import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import CodeMirrorDialog from './CodeMirrorDialog';
import './model.css';
import { useNavigate } from 'react-router-dom';
import QuestionsListWithInsert from '../../pages/question/QuestionsListWithInsert';
import Loader from '../Loader';
import LoadingSkeleton from '../skeleton/LoadingSkeleton';

const TinyEditor = ({
    fieldname, initialValue, setFieldValue, questions, label, questionlabel, id,
    title, orderList, selectedPart, section
}) => {
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [loading, setLoading] = useState(true); // Loading state

    // Handle editor content changes
    const handleEditorChange = (content, editor) => {
        setEditorContent(content); // Update state without committing to Formik
    };

    const initEditor = (editor) => {
        editor.ui.registry.addButton('codeEditor', {
            text: 'Code Editor',
            onAction: () => {
                setEditorContent(editor.getContent());
                setIsModalOpen(true);
            },
        });
    };

    const handleSave = (updatedContent) => {
        if (editorRef.current) {
            editorRef.current.setContent(updatedContent);
        }
        setIsModalOpen(false);
    };

    // Load and highlight initial content
    useEffect(() => {
        if (initialValue) {
            const highlightedContent = highlightPatterns(initialValue);
            setEditorContent(highlightedContent);
        }
        setLoading(false); // Hide loader after content is ready
    }, [initialValue]);

    const highlightPatterns = (text) => {
        const strippedText = text.replace(/<span id="(.*?)" style="background-color: yellow;">(.*?)<\/span>/g, '[$1]');
        const pattern = /\[(.*?)\]/g;
        return strippedText.replace(pattern, '<span id="$1" style="background-color: yellow;">[$1]</span>');
    };

    const insertQuestionId = (questionId) => {
        const editor = editorRef.current;
        if (editor) {
            const insertContent = `<span style="color: blue; font-style: italic;">[${questionId}]</span>`;
            editor.focus();
            editor.selection?.setContent(insertContent);
            setEditorContent(editor.getContent());
        }
    };

    const handleBlur = () => {
        const editor = editorRef.current;
        if (editor) {
            const highlightedContent = highlightPatterns(editor.getContent());
            setFieldValue(fieldname, highlightedContent);
        }
    };

    const handleImageUpload = (blobInfo, progress) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const uploadUrl = `${process.env.REACT_APP_API_BASE_URL}/api/file/upload`;

            xhr.open('POST', uploadUrl, true);

            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const percent = (e.loaded / e.total) * 100;
                    progress(percent);
                }
            };

            xhr.onload = () => {
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject(`HTTP Error: ${xhr.status}`);
                    return;
                }
                const json = JSON.parse(xhr.responseText);
                resolve(json.location);
            };

            xhr.onerror = () => {
                reject({ message: 'Image upload failed due to a network error', remove: true });
            };

            xhr.send(formData);
        });
    };

    const handleEdit = (quesId) => {
        navigate(`/question/edit/${quesId}`, {
            state: { QuestionId: id, QuestionTitle: title, OrderList: orderList, SelectedPart: selectedPart, Section: section }
        });
    };

    const navigateToAddNew = () => {
        navigate('/question/add', {
            state: { QuestionId: id, QuestionTitle: title, OrderList: orderList, SelectedPart: selectedPart, Section: section }
        });
    };

    return (
        <>
            <h3 className="text-xl font-medium mb-5 mt-10">{label}</h3>
            <div className="grid grid-cols-[70%,1fr] gap-8">
                <div>
                    {loading ? (
                        // Loader Component
                        <LoadingSkeleton />
                    ) : (
                        <>
                      
                            <Editor
                                apiKey="mfubp5wh9q48o9avo78nk1nikio8st2dmqd13yjk2jn1o30y"
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                value={editorContent}
                                onEditorChange={handleEditorChange}
                                onBlur={handleBlur}
                                textareaName={fieldname}
                                init={{
                                    plugins: [
                                        'anchor', 'autolink', 'charmap', 'codesample', 'emoticons',
                                        'image', 'link', 'lists', 'media', 'searchreplace',
                                        'table', 'visualblocks', 'wordcount', 'code'
                                    ],
                                    toolbar: 'undo redo blocks bold italic underline image media table | align | numlist bullist indent outdent | codeEditor',
                                    setup: initEditor,
                                    images_upload_handler: handleImageUpload,
                                }}
                            />
                            <CodeMirrorDialog
                                content={editorContent}
                                isOpen={isModalOpen}
                                onSave={handleSave}
                                onClose={() => setIsModalOpen(false)}
                            />

                        </>
                    )}
                </div>
                <div>
                    <QuestionsListWithInsert
                        questions={questions}
                        insertQuestionId={insertQuestionId}
                        handleEdit={handleEdit}
                        navigateToAddNew={navigateToAddNew}
                        section={section}
                        questionlabel={questionlabel}
                    />
                </div>
            </div>
        </>
    );
};

export default TinyEditor;
