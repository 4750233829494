import { ErrorMessage, Field } from "formik";
import React from "react";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikNumberField = ({ label, name, formik, ...props }) => {
  const { t } = useTranslation();
  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium "
        htmlFor={label}
      >
        {t(label)}
      </label>
      <Field
        type="number"
        {...props}
        name={name}
        className={`block w-full px-4 py-2 mb-2 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${
          formik?.touched?.[name] && formik?.errors?.[name]
            ? "border-red-600"
            : null
        }`}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikNumberField;
