import * as yup from 'yup';

let SettingValidationSchema = yup.object().shape({
    status: yup.boolean(),
    title: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    uniqueName: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    settingValue: yup.array().nullable(),

});


export default SettingValidationSchema