import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import SaveButton from "../../common/button/SaveButton";
import FormikCheckBox from "../../common/form/FormikCheckBox";
import FormikImageField from "../../common/form/FormikImageField";
import FormikRadioField from "../../common/form/FormikRadioField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import FormikGeoLocationField from "../../common/form/FormikGeoLocationField";
import * as yup from "yup";

const OwnerResponseForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [stateData, setStateData] = useState({
    QuestionGroup: "",
    DisplayNumber: "",
  });

  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      const { QuestionGroup, DisplayNumber } = state;
      setStateData({
        QuestionGroup,
        DisplayNumber,
      });
    }
    // eslint-disable-next-line
  }, [state]);

  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionDataLength, setQuestionDataLength] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [form, setForm] = useState({});
  const [validationSchema, setValidationSchema] = useState(null);
  const [displayType, setDisplayType] = useState(0);

  const [nameForm, setNameForm] = useState({
    User: `${new Date().toISOString().split("T")[0]}-${Math.floor(
      Math.random() * (9999 - 9000 + 1) + 2000
    )}`,
  });
  const [nameSuccess, setNameSuccess] = useState(false);


  const inputs = {
    Input: FormikTextField,
    LongText: FormikTextAreaField,
    Radio: FormikRadioField,
    Checkbox: FormikCheckBox,
    Image: FormikImageField,
    Switch: FormikSwitchField,
    Dropdown: FormikSelectSingleField,
    Location: FormikGeoLocationField,
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  const handleInput = (item, formik) => {
    const { questionType, options, label, name, isRequired, validation } = item;
    let objectProps = {
      name,
      label: isRequired ? `${label} *` : label,
      formik,
    };
    if (questionType === "Dropdown") {
      objectProps.handleSelectSingle = handleSelectSingle;
    }
    if (["Dropdown", "Radio", "Checkbox"].includes(questionType)) {
      objectProps.options = options?.map((item) => ({
        label: item?.Name,
        value: item?.Name,
      }));
    }

    if (["email", "Number", "Date"].includes(validation)) {
      objectProps.type = validation;
    }
    const Component = inputs[questionType];
    return <Component {...objectProps} />;
  };

  const createFormObj = (data, previous = false) => {
    let questionData = [];
    let initialValues = {};
    let validationObj = {};

    data?.forEach((item) => {
      const {
        _id,
        OrderBy,
        Type,
        Options,
        Detail,
        Slug,
        DevnagariTitle,
        IsRequired,
        Validation,
      } = item;

      initialValues[Slug] = form[Slug] || ""; // Preserve existing values

      let obj = {
        questionId: _id,
        label: DevnagariTitle,
        questionOrder: OrderBy,
        questionType: Type,
        options: Options,
        questionDetail: Detail,
        name: Slug,
        isRequired: IsRequired,
        validation: Validation,
      };
      questionData.push(obj);
      setQuestionDataLength(questionData?.length)

      if (IsRequired && !(Type === "Image" || Type === "Checkbox")) {
        validationObj[Slug] = yup.string().required("Required !");
      }

      if (IsRequired && Type === "Image") {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "image/png",
        ];

        validationObj[Slug] = yup
          .mixed()
          .required("Required !")
          .test("fileSize", "The file is too large", (value) => {
            if (!value) return true;
            return value && value.size <= 1024 * 1024 * 2;
          })
          .test(
            "fileFormat",
            "Unsupported File Format",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          );
      }
    });

    setValidationSchema(yup.object().shape(validationObj));

    setForm((prev) => ({ ...prev, ...initialValues }));

    if (previous) {
      const newArray = questions.slice(0, -questionDataLength)
      setQuestions(newArray)
    } else {
      setQuestions((prev) => [...prev, ...questionData])
    }



  };
  const handleNextButtonClick = () => {
    const lastIndex = currentQuestionIndex + displayType;
    createFormObj(allQuestionsData.slice(lastIndex, lastIndex + displayType));
    setCurrentQuestionIndex(lastIndex);
  };

  const handlePreviousButtonClick = () => {
    if (currentQuestionIndex > 0) {
      const lastIndex = currentQuestionIndex - displayType;
      createFormObj(
        allQuestionsData.slice(lastIndex, lastIndex + displayType), true);
      setCurrentQuestionIndex(lastIndex);
    }
  };


  const handleFormikFormSubmit = async (values, actions) => {
    if (!(currentQuestionIndex + displayType >= allQuestionsData.length)) {
      setForm((prev) => ({ ...prev, ...values }));
      handleNextButtonClick();
      return;
    }


    let { setSubmitting } = actions;

    let formData = new FormData();
    formData.append("User", nameForm.User);
    formData.append("QuestionGroup", stateData.QuestionGroup);
    formData.append("ResponseType", "Survey");
    formData.append("Submitted", true);

    questions.forEach((item) => {
      let { name, questionType } = item;
      if (questionType === "Image" && values[name]) {
        formData.append("Images", values[name]);
        formData.append("image", name);
      } else if (questionType === "Checkbox") {
        formData.append(name, JSON.stringify(values[name]));
      } else {
        formData.append(name, values[name]);
      }
    });
    const { success, message } = await new APIServices("/response").post(
      formData
    );
    if (success) {
      emitSuccessToast(message);
      navigate(`/question-group/view/${stateData.QuestionGroup}`);
    } else {
      emitErrorToast(message);
    }

    setSubmitting(false);
  };

  const handleNameSubmit = async (values) => {
    const { User } = values;
    if (!User.trim()) {
      return;
    }

    const payload = {
      User,
      QuestionGroup: stateData.QuestionGroup,
    };

    const { data, message, success } = await new APIServices(
      "/question/username"
    ).post(payload);

    if (success) {
      setNameForm({ User: User });
      setAllQuestionsData(data);
      const display = stateData.DisplayNumber === "All"
        ? data?.length
        : parseInt(stateData.DisplayNumber);
      setDisplayType(display)
      createFormObj(data.slice(0, display));
      setNameSuccess(true);
    } else {
      emitErrorToast(message);
    }
  };

  return (
    <section className="flex justify-center py-16 bg-gray-100 dark:bg-gray-800">
      <div className="w-full max-w-5xl px-4 mx-auto">
        {!nameSuccess && (
          <Formik
            initialValues={nameForm}
            onSubmit={handleNameSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <FormikTextField
                  name="User"
                  placeholder="Save response as .."
                  label="Please provide your name"
                  formik={formik}
                />
                <SaveButton isSubmitting={formik.isSubmitting} />
              </Form>
            )}
          </Formik>
        )}

        {Object.keys(form).length > 0 && (
          <Formik
            initialValues={form}
            onSubmit={handleFormikFormSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="">
                  <div>
                    <FormHeading title={`Survey Form `} />

                    <DashCard>
                      {questions.length > 0 &&
                        questions
                          ?.slice(
                            currentQuestionIndex,
                            currentQuestionIndex + displayType
                          )
                          .map((item, idx) => (
                            <React.Fragment key={idx}>
                              {handleInput(item, formik)}
                            </React.Fragment>
                          ))}
                      <div className="flex justify-between mt-2">
                        {currentQuestionIndex > 0 && (
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-primary transition ease-in-out duration-150"
                            onClick={handlePreviousButtonClick}
                            disabled={currentQuestionIndex === 0}
                          >
                            Previous
                          </button>

                        )}
                        <SaveButton
                          isSubmitting={formik.isSubmitting}
                          label={
                            currentQuestionIndex + displayType >=
                              allQuestionsData.length
                              ? "Submit"
                              : "Next"
                          }
                        />
                      </div>
                    </DashCard>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </section>
  );
};

export default OwnerResponseForm;
