import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import CategoryForm from "../../container/category/CategoryForm";

const EditCategory = () => {
    const { id } = useParams();
    const [dataToEdit, setDataToEdit] = useState([]);
    useEffect(() => {
        if (id) getDetail();
        // eslint-disable-next-line
    }, []);

    const getDetail = async () => {
        const { data, success } = await new APIServices(`category/${id}`).get();
        if (success) {
            let payload = {
                ...data,
                status: data.status === "Active" ? true : false,
            };
            setDataToEdit({ ...payload });
        }
    };

    return (
        <>
            <CategoryForm dataToEdit={dataToEdit} id={id} />
        </>
    );
};

export default EditCategory;
