import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import PostForm from "../../container/post/PostForm";
import { useLocation } from 'react-router-dom';

const EditPost = () => {
  const { state } = useLocation();
  const { pagetype } = state;
  // The ID you get here is SLUG
  const { id } = useParams();
  const [postDataToEdit, setPostDataToEdit] = useState([]);
  useEffect(() => {
    if (id) getPostDetail();
    // eslint-disable-next-line
  }, [id]);

  const getPostDetail = async () => {
    const { data, success } = await new APIServices(`post/${id}`).get();
    if (success) {
      let payload = {
        ...data,
        status: data.status === "Active" ? true : false,
        isVerified: data.isVerified === "Yes" ? true : false,
        tags: data?.tags || [],
      };
      setPostDataToEdit({ ...payload });
    }
  };

  return (
    <>
      <PostForm data={postDataToEdit} id={id} pagetype={pagetype} />
    </>
  );
};

export default EditPost;
