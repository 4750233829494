import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const ColumnChart = ({ title, chartData }) => {
  const divId = `${title}-${uuidv4()}`;
  const histogram = () => {
    const data = window.google.visualization.arrayToDataTable(chartData);

    var options = {
      title: title,
      vAxis: {
        title: "Total Count",
      },
      hAxis: {
        title: "gender",
      },
    };

    const chart = new window.google.visualization.ColumnChart(
      document.getElementById(divId)
    );

    chart.draw(data, options);
  };

  useEffect(() => {
    if (chartData)
      window.google.charts.setOnLoadCallback(histogram);
    // eslint-disable-next-line
  }, [chartData]);

  return (
    <div>
      <div id={divId} className="w-full h-96"></div>
    </div>
  );
};

export default ColumnChart;
