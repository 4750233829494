import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextError } from '../../common/TextError';
import APIServices from '../../../httpServices/httpServices';
import { emitSuccessToast } from '../../common/toast/ToastContainer';
import slugify from 'slugify'
import FormikTextField from '../../common/form/FormikTextField';
import FormikImageField from '../../common/form/FormikImageField';
import FormikTextAreaField from '../../common/form/FormikTextAreaField';
import FormikSwitchField from '../../common/form/FormikSwitchField';
import { useNavigate } from 'react-router-dom';
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
import TagValidationSchema from '../../../validation/TagValidationSchema';
import { t } from 'i18next';
import SaveButton from '../../common/button/SaveButton';

const CategoryForm = ({ dataToEdit, id }) => {
    const navigate = useNavigate()
    const [initialValues, setInitialValues] = useState({
        status: false,
        title: "",
        slug: "",
        image: "",
        specialValue: "",
        detail: "",
        seoTitle: "",
        seoDescription: "",
    })


    useEffect(() => {
        if (dataToEdit)
            setInitialValues((prev) => ({
                ...prev,
                ...dataToEdit,
            }));
        // eslint-disable-next-line
    }, [dataToEdit])

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm, setFieldError } = actions
        let payload = {
            ...values,
            status: values.status ? "Active" : "Inactive",
            seoTitle: values?.seoTitle || values?.title,
            seoDescription: values?.seoDescription || values?.detail
        }
        let formData = new FormData();

        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });

        !!id && formData.append("oldTitle", dataToEdit?.title)

        const { success, data, message } = !!!id ? await new APIServices(`category`).post(formData) : await new APIServices(`category/${id}`).put(formData)
        if (success) {
            setSubmitting(false);
            resetForm();
            emitSuccessToast(message)
            navigate(`/category/view/${data._id}`, { replace: true })
        } else {
            if (message === "slug already exists.") {
                setFieldError("title", "Tag already exist.")
            }
        }

    }


    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={TagValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {
                        formik => (
                            <Form>

                                <div className="grid grid-cols-[70%,1fr] gap-4">
                                    <div>
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300">Category's Information
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">

                                            <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium " htmlFor="">
                                                        {t('title *')}
                                                    </label>
                                                    <Field type="text" name="title" placeholder="Enter a title" >
                                                        {({ field, form, meta }) => {
                                                            return <input
                                                                name="title"
                                                                type="Text"
                                                                placeholder="Enter a title"
                                                                value={field.value || ""}
                                                                onChange={e => { form.setFieldValue("slug", slugify(e.target.value, { lower: true })); form.setFieldValue(field.name, e.target.value) }}
                                                                onBlur={form.handleBlur}
                                                                className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${formik.touched.title && formik.errors.title ? "border-red-600" : null}`}
                                                            />
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="title" component={TextError} />
                                                </div>

                                                <FormikTextField label="slug *" name="slug" formik={formik} />

                                            </div>
                                            {/* Details */}
                                            <FormikTextAreaField label="detail *" name="detail" formik={formik} />
                                        </div>


                                        {/* SEO AND SEO DESCRIPTION  */}
                                        <h3 className="mb-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-300"> SEO Section
                                        </h3>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow">
                                            <div className="mb-6">
                                                <FormikTextField label="Seo title" name="seoTitle" formik={formik} />
                                            </div>
                                            <div className="">
                                                <FormikTextAreaField label="Seo Description" name="seoDescription" formik={formik} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikSwitchField label="status " name="status" />
                                        </div>
                                        <div className="p-6 mb-4 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                                            <FormikImageField label="image " name="image" formik={formik} />
                                        </div>

                                    </div>
                                </div>


                                {/* Submit */}
                                <div className="mt-7">
                                    <div className="flex justify-start space-x-2">
                                        <SaveButton isSubmitting={formik.isSubmitting} label={"Save"} />
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </section >
    )
}

export default CategoryForm