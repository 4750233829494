import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  question: null,
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setQuestion: (state, action) => {
      const { payload } = action;

      state.question = payload;
    },
  },

  extraReducers: (builder) => { },
});

export const { setQuestion } = questionSlice.actions;

export default questionSlice.reducer;
