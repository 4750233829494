import React from 'react'
import PrepareQuestionForm from '../../container/prepareQuestion/PrepareQuestionForm'

const PrepareQuestion = () => {

    return (
        <div><PrepareQuestionForm /></div>
    )
}

export default PrepareQuestion