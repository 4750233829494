import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import QuestionList from "../question/QuestionList";

const ViewQuestionGroup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [prepareQuestionData, setPrepareQuestionData] = useState({});

  useEffect(() => {
    if (id) {
      getDetailView();
      getPrepareQuestion();
    }
    // eslint-disable-next-line
  }, [id]);

  // Fetch User Data
  const getDetailView = async () => {
    const { data, success } = await new APIServices(
      `reading-group/${id}`
    ).get();
    if (success) {
      setData(data);
    }
  };

  const getPrepareQuestion = async () => {
    const { data, success } = await new APIServices(`prepare-question/${id}`).get();
    if (success) {
      setPrepareQuestionData(data)
    }
  };
  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteRole = async () => {
    const { message, success } = await new APIServices(
      `reading-group/${id}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/reading-group");
    }
  };

  const {
    _id,
    title,
    detail,
    status,
    level
  } = data;

  return (
    <>
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="flex justify-between mt-5 mb-4 items-end">
          <h2 className="font-bold text-lg"> Reading Group Information </h2>
        </div>
        <div className="flex items-center justify-between">
          <DataView label="title" value={title} />
          <div className="flex items-center">
            <div
              className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                }`}
            ></div>
            <span className="text-sm"> {status} </span>
          </div>
          {status === "Inactive" && (
            <button
              onClick={() => handleShowDeleteModal(_id)}
              className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
            >
              Delete
            </button>
          )}
        </div>

        <div className="shadow bg-white border-gray-500 p-8 rounded">
          <div className="flex flex-wrap">
            <DataView label="level" value={level} />
            <DataView label="detail" value={detail} />

          </div>
        </div>

        {showModal && (
          <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
        )}

        <div className="flex justify-between mt-4">
          {/* <button
            onClick={() =>
              navigate(`/response`, {
                state: {
                  QuestionGroup: _id,
                  DisplayNumber: DisplayNumber,
                },
              })
            }
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            Start a survey
          </button> */}
          <Link
            to={`/prepare-question/add/${_id}/1?title=${title}`}
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            Prepare Questions
          </Link>

          {/* <Link
            to={`/prepare-question/detail/${id}`}
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            View Prepared Questions
          </Link> */}

          {/* <Link
            to={`/response-list/${_id}`}
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            Response List
          </Link> */}
        </div>
      </div>
      {/* <QuestionList Id={_id} title={title} /> */}
    </>
  );
};

export default ViewQuestionGroup;
