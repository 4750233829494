import React from 'react'
import CategoryForm from '../../container/category/CategoryForm'

const AddCategory = () => {
    return (
        <div><CategoryForm /></div>
    )
}

export default AddCategory
