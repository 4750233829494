import * as yup from 'yup';
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

let TagValidationSchema = yup.object().shape({
    status: yup.boolean(),
    title: yup.string().max(40, 'Must be 40 characters or less').required('Required !'),
    slug: yup.string().max(50, 'Must be 50 characters or less').required('Required !'),
    image: yup.mixed()
        .notRequired()
        .nullable()
        .test('fileSize',
            'The file is too large', (value) => {
                if (!value) return true
                return value && value.size <= (1024 * 1024) * 2
            })
        .test('fileFormat',
            'Unsupported File Format', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),

    detail: yup.string().required('Required !'),
    seoTitle: yup.string(),
    seoDescription: yup.string(),

});


export default TagValidationSchema