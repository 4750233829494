import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import moment from "moment";

const QuestionGroupList = () => {
    const navigate = useNavigate();
    const tableHeading = ["title", "status", "Action"];
    const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT;

    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable();
            }, 1500);
        } else {
            getListOfDataToShowOnTable();
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [searchTxt, currentPage]);

    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            "reading-group/list"
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
        });

        if (success) {
            setIsLoading(false);
            setDataListToShowOnTable(
                data.map((request) => ({
                    ...request,
                    title: request.title
                }))
            );
            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };

    const handleEdit = (id) => {
        navigate(`/question-group/edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/question-group/view/${id}`);
    };

    const navigateToAddNew = () => {
        navigate("/question-group/add");
    };

    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };

    const handlePreviewQuestion = (id, title) => {
        navigate(`/prepare-question/add/${id}/1?title=${title}`);
    };

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <>
                            <TableEntry
                                title="List of Reading Groups"
                                navigate={navigateToAddNew}
                                handleTableSearch={handleTableSearch}
                            />
                            <CustomTable
                                tableData={dataListToShowOnTable}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                handleEdit={handleEdit}
                                handleView={handleView}
                                handlePreviewQuestion={handlePreviewQuestion}
                                actionField="_id"
                            />
                            <TablePagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesNumber={totalPagesNumber}
                                numberOfTotalData={numberOfTotalData}
                                limit={limit}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default QuestionGroupList;
