import * as yup from "yup";
const regExeNum = /^9\d{9}$/;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

let UserValidationSchema = yup.object().shape({
  status: yup.boolean(),
  name: yup
    .string()
    .max(20, "Must be 20 characters or less")
    .required("Required !"),
  email: yup.string().email("Invalid email address"),
  profileImage: yup
    .mixed()
    .notRequired()
    .nullable()
    .test("fileSize", "The file is too large", (value) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 2;
    })
    .test(
      "fileFormat",
      "Unsupported File Format",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  password: yup.string().required("Required !"),
  userType: yup.string().required("Required !"),
  role: yup.string().required("Required !"),
  aliasName: yup.string().max(20, "Must be 20 characters or less"),
  gender: yup
    .string()
    .oneOf(["Male", "Female"], "Invalid  gender")
    .required("Required !"),
  phone: yup
    .string()
    .required("Required !")
    .matches(regExeNum, "Enter valid 10 digit number"),
  address: yup.string(),

  referralUser: yup.string(),
  remarks: yup.string(),
});

export default UserValidationSchema;
