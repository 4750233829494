import React, { useState, useEffect } from "react";
import APIServices from "../../../httpServices/httpServices";
import DataView from "../../common/viewDetail/DataView";
import moment from "moment";
import { t } from "i18next";
import CustomImage from "../../common/CustomImage";

const Profile = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getUserDetailView();
    // eslint-disable-next-line
  }, []);

  // Fetch User Data
  const getUserDetailView = async () => {
    const { data, success } = await new APIServices(`admin-user/my-details`).get();
    if (success) {
      setUserData(data);
    }
  };

  const {
    address,
    aliasName,
    email,
    name,
    gender,
    phone,
    profileImage,
    remarks,
    role,
    status,
    userType,
  } = userData;

  return (
    <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
      <div className="flex gap-x-4">
        <div className="user-profile w-[180px] h-[180px]">
          <CustomImage
            className="object-cover w-full h-full rounded-md employee-avatar"
            source={!!profileImage ? profileImage : ""}
          />

        </div>
        <div className="flex-1">
          <div className="text-2xl font-bold" name="">
            {name}
          </div>
          <div className="mb-3 text-gray-500">
            <span className="text-base">{email}</span>
          </div>
          <div className="grid grid-cols-3 gap-x-2">
            {/* <div className="flex flex-col">
              <span className="block mb-0 text-sm font-normal text-gray-600">
                {t('Date of Birth')}
              </span>
              <span className="font-bold">
                {moment(DOB, moment.ISO_8601).format("LL")}
              </span>
            </div> */}

            <div className="flex flex-col">
              <span className="block mb-0 text-sm font-normal text-gray-600">
                {t('phone')}
              </span>
              <span className="mb-5 text-base font-semibold text-gray-800">

                {phone ? phone : "--"}
              </span>
            </div>

          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                  }`}
              ></div>
              <span className="text-sm">  {t(status)} </span>
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 mt-4 bg-white border-gray-500 rounded shadow mt-154">
        <h2 className="mt-5 mb-4 text-lg font-bold"> {t("Personal Information")} </h2>
        <div className="flex flex-wrap">
          <DataView label="Alias Name" value={aliasName} />
          <DataView label="role" value={role} />
          <DataView label="userType" value={userType} />
          <DataView label="address" value={address} />
          <DataView label="Remarks" value={remarks} />
          <DataView label="gender" value={gender} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
