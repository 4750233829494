import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const FormikSwitchField = ({ label, name, }) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <label className="block items-center cursor-pointer">
        <div className="block mb-2 text-sm font-medium ">
          {t(label)}
        </div>
        <div className="relative flex">
          <Field type="checkbox" id={label} name={name} className="sr-only" />
          <div className="block w-14 h-8 bg-gray-200 rounded-full"></div>
          <div className="absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1"></div>
        </div>
      </label>
    </div>
  );
};

export default FormikSwitchField;

// import { Field } from "formik";
// import React from "react";
// import { useTranslation } from "react-i18next";

// const FormikSwitchField = ({ label, name, checked, onChange }) => {
//   const { t } = useTranslation();

//   return (
//     <div className="">
//       <label className="block items-center cursor-pointer">
//         <div className="block mb-2 text-sm font-medium ">
//           {t(label)}
//         </div>
//         <div className="relative flex w-14">
//           <Field
//             type="checkbox"
//             id={name} // Use the name prop instead of label for id
//             name={name}
//             checked={checked} // Pass checked prop
//             onChange={onChange} // Pass onChange prop
//             className="sr-only"
//           />
//           <div className="block w-14 h-8 bg-gray-200 rounded-full"></div>
//           <div className={`absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1`}></div> {/* Adjust dot position based on checked state */}
//         </div>
//       </label>
//     </div>
//   );
// };

// export default FormikSwitchField;
