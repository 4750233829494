import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import PrepareQuestionDetail from '../../container/prepareQuestion/PrepareQuestionDetail';

const PrepareQuestionForm = () => {
    const { id, part } = useParams();
    const [prepareQuestion, setPrepareQuestion] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (id) {
            getDetail();
        }
        // eslint-disable-next-line
    }, [id]);

    const getDetail = async () => {
        const { success, data } = await new APIServices(`prepare-reading-set/${id}/?part=${part}`).get();
        if (success) {
            setPrepareQuestion(data.prepareQuestion);
            setQuestions(data.questions);
        }
    };


    return (
        <div className='max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6'>
            <div className="shadow bg-white border-gray-500  rounded">

                {prepareQuestion && questions.length > 0 && (
                    <PrepareQuestionDetail prepareQuestion={prepareQuestion} questions={questions} />
                )}
            </div>
        </div>
    );
};

export default PrepareQuestionForm;
