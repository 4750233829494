import React, { useEffect, useState } from "react";
import LineGraph from "./LineGraph";
import ColumnChart from "./ColumnChart";
import PieChart3D from "./PieChart3D";

const ChartReport = ({
  questionReportData,

}) => {
  const [chartsLoaded, setChartsLoaded] = useState(false);



  useEffect(() => {
    // Load Google Charts library
    if (typeof window !== "undefined" && window.google) {
      // Load the Google Charts library if not already loaded
      if (!window.google.visualization) {
        window.google.charts.load("current", { packages: ["corechart"] });

      }
      // Set callback for library loaded
      window.google.charts.setOnLoadCallback(() => {
        // Google Charts library is now loaded
        setChartsLoaded(true);
      });
    }

  }, []);

  return (
    <div className="mt-8">

      {chartsLoaded ? (
        <>

          {questionReportData && (
            <>
              <p className="text-2xl font-bold text">
                ColumnChart  Report
              </p>
              <div className="grid grid-cols-2 gap-4">
                <ColumnChart
                  title="Radio Option Data"
                  chartData={questionReportData}
                />

              </div>
            </>
          )}

          {/* {interventionReport && (
            <>
              <p className="text-2xl font-bold text">
                ColumnChart Milestone Report
              </p>
              <div className="grid grid-cols-2 gap-4">
                <ColumnChart
                  title="Helambu Rural Municipality"
                  chartData={interventionReport?.HEL}
                />
                <ColumnChart
                  title="Melamchi Municipality"
                  chartData={interventionReport?.MEL}
                />
              </div>
            </>
          )}

          {pieTaskReport && (
            <>
              <p className="text-2xl font-bold text">PieChat Milestone Report</p>
              <div className="grid grid-cols-2">
                <PieChart3D
                  title="Helambu Rural Municipality"
                  chartData={pieTaskReport?.HEL}
                />
                <PieChart3D
                  title="Melamchi Municipality"
                  chartData={pieTaskReport?.MEL}
                />
              </div>
            </>
          )} */}


        </>
      ) : <>No Report</>}
    </div>
  );
};

export default ChartReport;
