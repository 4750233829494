import React, { useState, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css'; // Import CodeMirror styles
import 'codemirror/mode/xml/xml'; // Import HTML mode
import 'codemirror/theme/material.css'; // Import dark theme for CodeMirror

export default function CodeMirrorDialog({ content, isOpen, onSave, onClose }) {
    const [editorContent, setEditorContent] = useState(content); // State to manage editor content

    // Sync content prop with local state when the modal opens
    useEffect(() => {
        if (isOpen) {
            setEditorContent(content); // Update editor content when modal opens
        }
    }, [content, isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Advanced HTML Code Editor</h2>
                <div className="codemirror-container"> {/* Ensure this container grows to fill the available space */}
                    <CodeMirror
                        value={editorContent} // Bind the CodeMirror value to state
                        options={{
                            mode: 'xml', // Set the mode to HTML
                            lineNumbers: true,
                            theme: 'material', // Set the dark theme for CodeMirror
                            styleActiveLine: true, // Highlight active line
                            styleSelectedText: true, // Highlight selected text
                            readOnly: false, // Ensure editor is editable
                            lineWrapping: true, // Enable word wrap
                        }}
                        onBeforeChange={(editor, data, value) => {
                            // Update the editor content as the user types
                            setEditorContent(value); // Update state with the new value
                        }}
                        onChange={(editor, data, value) => {
                            // Capture changes (optional)
                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <button onClick={() => onSave(editorContent)}>Save</button> {/* Use editorContent state */}
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
