// import React from 'react';

// const PrepareQuestionDetail = ({ prepareQuestion, questions }) => {
//     console.log("prepareQuestion", prepareQuestion)
//     console.log("questions", questions)
//     const renderDetail = () => {
//         const detail = prepareQuestion.detail;
//         const regex = /\[(.*?)\]/g;
//         const matches = detail.match(regex);

//         if (!matches) return detail;

//         return detail.split(regex).map((item, index) => {
//             if (matches.includes(`[${item}]`)) {
//                 const questionId = item; // Placeholder contains the question ID
//                 const question = questions.find(q => q._id === questionId);
//                 if (question) {
//                     if (question.type === "Dropdown") {
//                         return (
//                             <select key={index}>
//                                 <option value=""></option> {/* Empty option for unselected state */}
//                                 {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
//                                     <option key={i} value={option.name}>{option.name}</option>
//                                 ))}
//                             </select>
//                         );
//                     } else if (question.type === "Radio") {
//                         return (
//                             <div key={index}>
//                                 {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
//                                     <label key={i}>
//                                         <input type="radio" name={`radio_${index}`} value={option.name} /> {option.name}
//                                     </label>
//                                 ))}
//                             </div>
//                         );
//                     } else {
//                         return `[${item}]`;
//                     }
//                 } else {
//                     return `[${item}]`;
//                 }
//             } else {
//                 // Use dangerouslySetInnerHTML for HTML content
//                 return <span key={index} dangerouslySetInnerHTML={{ __html: item }} />;
//             }
//         });
//     };

//     return (
//         <div className="flex">
//             <div className="w-1/2">
//                 <div className=" p-6 bg-gray-50 text-sm text-gray-700 leading-loose" dangerouslySetInnerHTML={{ __html: prepareQuestion.questionText }} /></div>

//             <div className="w-1/2 bg-sky-100">
//                 <div className="p-8">
//                     {/* {renderDetail()} */}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PrepareQuestionDetail;


import React from 'react';

const PrepareQuestionDetail = ({ prepareQuestion, questions }) => {

    const renderSection = (sectionContent) => {
        const regex = /\[(.*?)\]/g;
        const matches = sectionContent.match(regex);

        if (!matches) return sectionContent;

        return sectionContent.split(regex).map((item, index) => {
            if (matches.includes(`[${item}]`)) {
                const questionId = item; // Placeholder contains the question ID
                const question = questions.find(q => q._id === questionId);
                if (question) {
                    if (question.type === "Dropdown") {
                        return (
                            <select key={index}>
                                <option value=""></option> {/* Empty option for unselected state */}
                                {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                    <option key={i} value={option.name}>{option.name}</option>
                                ))}
                            </select>
                        );
                    } else if (question.type === "Radio") {
                        return (
                            <div key={index}>
                                {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                    <label key={i}>
                                        <input type="radio" name={`radio_${index}`} value={option.name} /> {option.name}
                                    </label>
                                ))}
                            </div>
                        );
                    } else {
                        return `[${item}]`;
                    }
                } else {
                    return `[${item}]`;
                }
            } else {
                // Use dangerouslySetInnerHTML for HTML content
                // return <span key={index} dangerouslySetInnerHTML={{ __html: item }} />;
                return <span key={index}>{item.split('\n').map((line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)}</span>;
            }
        });
    };

    return (
        <div className="flex">
            <div className="w-1/2">
                <div className=" p-6 bg-gray-50 text-sm text-gray-700 leading-loose" dangerouslySetInnerHTML={{ __html: prepareQuestion.questionText }} />
            </div>

            <div className="w-1/2 bg-sky-100">
                <div className="p-8">
                    <div>
                        {prepareQuestion.detail && prepareQuestion.detail[0] && (
                            <>
                                <div>
                                    <div className='font-bold mb-2'>
                                        Using the drop-down menu (  ), choose the best option according to the information given in the message.
                                    </div>
                                    {["1", "3", "4"].includes(prepareQuestion?.part) ? (
                                        <div>
                                            {questions
                                                .filter(q => q.part === prepareQuestion?.part && q.section === "1")
                                                .map((question, index) => (
                                                    <div key={index}>
                                                        <div className='mb-2'>{question.title}
                                                            {question.type === "Dropdown" ? (
                                                                <select className='mx-2'>
                                                                    <option value=""></option> {/* Empty option for unselected state */}
                                                                    {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                                                        <option key={i} value={option.name}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <div>
                                                                    {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                                                        <label key={i}>
                                                                            <input type="radio" name={`radio_${index}`} value={option.name} /> {option.name}
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                        <div>
                                            {renderSection(prepareQuestion?.detail[0]?.sectionA)}
                                        </div>
                                    )}

                                </div>
                                <div>
                                    <div className='font-bold mb-2'>

                                        Here is a response to the message. Complete the response by filling in the blanks. Select the best choice for each blank from the drop-down menu
                                        (  ).
                                    </div>
                                    {prepareQuestion?.part === "2" ? (
                                        <div>
                                            {questions
                                                .filter(q => q.part === prepareQuestion?.part && q.section === "2")
                                                .map((question, index) => (
                                                    <div key={index}>
                                                        <div className='mb-2'>{question.title}
                                                            {question.type === "Dropdown" ? (
                                                                <select className='mx-2'>
                                                                    <option value=""></option> {/* Empty option for unselected state */}
                                                                    {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                                                        <option key={i} value={option.name}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <div>
                                                                    {question.options.filter(option => option.name.trim() !== "").map((option, i) => (
                                                                        <label key={i}>
                                                                            <input type="radio" name={`radio_${index}`} value={option.name} /> {option.name}
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                        <div>
                                            {renderSection(prepareQuestion?.detail[0]?.sectionB)}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrepareQuestionDetail;



