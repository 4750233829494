import React from "react";

const AdminHome = () => {

  // useEffect(() => {
  //   getReport();

  //   // eslint-disable-next-line
  // }, []);

  // // Fetch User Data
  // const getReport = async () => {
  //   const { data, success } = await new APIServices(`/dashboard`).get();
  //   if (success) {
  //     console.log("data", data)
  //   }
  // };

  return (
    <>
      <div className="text-xl font-bold text-center">Welcome to Celpip Admin Panel</div>

    </>
  );
};

export default AdminHome;
