import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import QuestionOptionForm from "../../common/QuestionOptionForm";
import SaveButton from "../../common/button/SaveButton";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import * as Yup from 'yup';
import FormikTextAreaField from "../../common/form/FormikTextAreaField";

const QuestionForm = ({ editData }) => {
  const navigate = useNavigate();
  const [questionTypeOptions, setQuestionTypeOptions] = useState([]);
  const { QuestionId, QuestionTitle, SelectedPart, Section } = useLocation().state;

  const initialValues = {
    status: true,
    title: "",
    slug: "",
    part: SelectedPart,
    section: Section,
    detail: "",
    type: "Dropdown",
    hint: "",
    questionType: "",
    explanation: "",
    isRequired: false,
    validation: "Null",
    options: [],
    readingGroupId: QuestionId,
    showTag: false,
  };


  const [form, setForm] = useState({ ...initialValues });

  let typeOptions = [
    { label: "Select One", value: "Dropdown" },
    // { label: "Single Line Text", value: "Input" },
    // { label: "Multi-line Text", value: "LongText" },
    // { label: "Choose Only One", value: "Radio" },
    // { label: "Choose Multiple Option", value: "Checkbox" },
    // { label: "Image", value: "Image" },
    // { label: "On/Off Button", value: "Switch" },
    // { label: "Location", value: "Location" },
  ]
  const validation = ["Null", "Number"];
  let validationOptions = validation.map((item) => ({
    value: item,
    label: item,
  }));

  useEffect(() => {
    if (editData)
      setForm((prev) => ({
        ...prev,
        ...editData,
      }));

    // eslint-disable-next-line
  }, [editData]);

  useEffect(() => {
    getFormSelectFieldValues();
    // eslint-disable-next-line
  }, []);

  const handleFormikFormSubmit = async (values, actions) => {
    let { setSubmitting, resetForm } = actions;

    let payload = {
      ...values,
      slug: slugify(values.title, { replacement: "_", lower: true }),
      status: values.status ? "Active" : "Inactive",
    };
    const { success, message } = !!!editData
      ? await new APIServices(`reading-question`).post(payload)
      : await new APIServices(`reading-question/${editData._id}`).put(payload);
    if (success) {
      emitSuccessToast(message);
      setSubmitting(false);
      resetForm();
      navigate(`/prepare-question/add/${QuestionId}/${SelectedPart}`);
    } else {
      emitErrorToast(message);
    }
  };
  // const handleNameChange = (value, form) => {
  //   form.setFieldValue("title", value);
  //   form.setFieldValue(
  //     "slug",
  //     slugify(value, { replacement: "_", lower: true })
  //   );
  // };
  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  const getFormSelectFieldValues = async () => {
    const [questionTypeObj] = await Promise.all([
      new APIServices("setting/get/question-type").get(),
    ]);
    if (questionTypeObj.success) {
      const { settingValue } = questionTypeObj.data;
      let options = JSON.parse(settingValue)?.map((item) => ({
        value: item,
        label: item,
      }));
      setQuestionTypeOptions(options);
    }
  };

  const validate = Yup.object().shape({
    status: Yup.boolean(),
    title: Yup.string().required("Required !"),
    // OrderBy: !editData ? Yup.number()
    //   .required('Required').notOneOf(OrderList, "Order number already exists. please make it unique")
    //   : Yup.number().required('Required'),
    type: Yup.string().required("Required !"),
  });

  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-6xl px-4 mx-auto ">
        <Formik
          initialValues={form}
          validationSchema={validate}
          onSubmit={handleFormikFormSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <div className="grid gap-4">
                <div>
                  <FormHeading title={`Add Questions for ${QuestionTitle} for Section ${Section}`} />
                  <DashCard>
                    <FormikTextField
                      label="title"
                      name="title"
                      placeholder="Enter a title"
                      formik={formik}
                    />

                    {/* <FormikTextField
                      label="slug"
                      name="slug"
                      formik={formik}
                    /> */}
                    <FormikTextField
                      label="detail"
                      name="detail"
                      as="textarea"
                      formik={formik}
                    />
                    {/* <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      <FormikSwitchField label="Show Tag" name="showTag" />
                    </div> */}
                    {/* <FormikSwitchField label="ShowAdvanceOption" name="ShowAdvanceOption" /> */}

                    <FormikSelectSingleField
                      label="type "
                      name="type"
                      options={typeOptions}
                      handleSelectSingle={handleSelectSingle}
                    />

                    {formik.values.type === "Radio" ||
                      formik.values.type === "Dropdown" ||
                      formik.values.type === "Checkbox" ? (
                      <QuestionOptionForm formik={formik} />
                    ) : null}

                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      {formik.values.type === "Input" ||
                        formik.values.type === "Long Text" ? (
                        <>
                          <FormikSelectSingleField
                            label="validation "
                            name="validation"
                            options={validationOptions}
                            handleSelectSingle={handleSelectSingle}
                          />
                        </>
                      ) : null}



                    </div>
                    <FormikTextField
                      label="hint"
                      name="hint"
                      as="textarea"
                      formik={formik}
                    />
                    <div className="mb-4">
                      <FormikSelectSingleField
                        label="Question Type *"
                        name="questionType"
                        options={questionTypeOptions}
                        handleSelectSingle={handleSelectSingle}
                      />
                    </div>
                    <FormikTextAreaField
                      label="explanation"
                      name="explanation"
                      as="textarea"
                      formik={formik}
                    />
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                      <FormikSwitchField label="Is Required" name="isRequired" />
                      <FormikSwitchField label="status" name="status" />
                    </div>
                  </DashCard>
                </div>
              </div>
              <SaveButton isSubmitting={formik.isSubmitting} />
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default QuestionForm;
