import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { GET_USER_PROFILE } from "../../../redux/sagas/actions";
import FormikImageField from "../../common/form/FormikImageField";
import FormikRadioField from "../../common/form/FormikRadioField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import UserValidationSchema from "../../../validation/UsersValidationSchema";

const UserForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userType, setUserType] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [referralUser, setReferralUser] = useState([]);
  const [userDataToEdit, setUserDataToEdit] = useState({
    name: "",
    email: "",
    // DOB: "",
    profileImage: "",
    aliasName: "",
    gender: "Male",
    password: "",
    phone: "",
    address: "",
    userType: "",
    role: "",
    referralUser: "",
    remarks: "",
    status: true,
  });

  const gender = ["Male", "Female", "Other"];

  let genderIdentityOptions = gender.map((d) => ({ value: d, label: d }));

  useEffect(() => {
    if (id) getUserDetail();
    getFormSelectFieldValues();
    // eslint-disable-next-line
  }, [id]);

  const getFormSelectFieldValues = async () => {
    const [userTypeObj, userRoleObj, referralUserObj,] =
      await Promise.all([
        new APIServices("setting/get/user-type").get(),
        new APIServices("role/list").post({}),
        new APIServices("admin-user/list").post({}),
      ]);
    if (userTypeObj.success) {
      setUserType(
        JSON.parse(userTypeObj.data?.settingValue).map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
    if (userRoleObj.success) {
      setUserRole(
        userRoleObj.data.map((item) => ({
          label: item.title,
          value: item.title,
        }))
      );
    }

    if (referralUserObj.success) {
      setReferralUser(
        referralUserObj.data.map((item) => ({
          label: item.name,
          value: item._id,
        }))
      );
    }
  };

  const getUserDetail = async () => {
    const { data } = await new APIServices(`admin-user/${id}`).get();
    if (data) {
      setUserDataToEdit((prev) => ({
        ...prev,
        ...data,
        status: data.status === "Active" ? true : false,
      }));
    }
  };

  const handleFormikFormSubmit = async (values, actions) => {
    let { setSubmitting, resetForm } = actions;
    let payload = {
      ...values,
      status: values.status ? "Active" : "Inactive",
      aliasName: !!values.phone ? values.phone : values.email.split("@")[0],
    };

    let formData = new FormData();
    Object.keys(payload).forEach((key, index) => {
      formData.append(key, payload[key]);
    });

    //if id then update else create new
    const { success, data, message } = !!id
      ? await new APIServices(`admin-user/${id}`).put(formData)
      : await new APIServices("admin-user").post(formData);
    if (success) {
      emitSuccessToast(message);
      setSubmitting(false);
      resetForm();
      getUserProfile();
      navigate(`/admin-users/view/${data._id}`);
    } else {
      emitErrorToast(message);
    }
  };

  const getUserProfile = () => {
    const payload = {
      url: "admin-user/my-details",
    };
    dispatch(GET_USER_PROFILE(payload));
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-4xl px-4 mx-auto ">
        <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
          <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">
            Admin User's Information
          </h2>
          <Formik
            initialValues={userDataToEdit}
            validationSchema={UserValidationSchema}
            onSubmit={handleFormikFormSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <FormikSwitchField
                  label="status"
                  name="status"
                  formik={formik}
                />

                <FormikTextField
                  label="Full Name"
                  name="name"
                  formik={formik}
                />
                <FormikTextField label="email" name="email" formik={formik} />

                {!id && (
                  <FormikTextField
                    label="Password"
                    name="password"
                    type="password"
                    formik={formik}
                  />
                )}

                {/* <FormikTextField
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                  formik={formik}
                /> */}

                <div className="p-6 mb-4 bg-white rounded-md shadow">
                  <FormikImageField label="Profile Image" name="profileImage" />
                </div>

                <FormikTextField
                  label="Alias Name"
                  name="aliasName"
                  formik={formik}
                />

                <FormikRadioField
                  label="gender Identity"
                  name="gender"
                  options={genderIdentityOptions}
                  formik={formik}
                />

                <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                  <FormikTextField
                    label="phone"
                    name="phone"
                    type="number"
                    formik={formik}
                  />
                </div>
                <FormikTextField
                  label="address"
                  name="address"
                  formik={formik}
                />

                <FormikSelectSingleField
                  label="Select User Type"
                  name="userType"
                  options={userType}
                  handleSelectSingle={handleSelectSingle}
                />
                <FormikSelectSingleField
                  label="Select role"
                  name="role"
                  options={userRole}
                  handleSelectSingle={handleSelectSingle}
                />

                <FormikSelectSingleField
                  label="Select Referral User"
                  name="referralUser"
                  options={referralUser}
                  handleSelectSingle={handleSelectSingle}
                />

                <FormikTextField
                  label="remarks"
                  name="remarks"
                  formik={formik}
                />

                {/* Submit */}

                <div className="mt-7">
                  <div className="flex justify-start space-x-2">
                    <button
                      type="submit"
                      className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default UserForm;
