import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { LOGIN } from "../../../redux/sagas/actions";
import LoginValidationSchema from "../../../validation/LoginValidationSchema";
import FormikTextField from "../../common/form/FormikTextField";
import { t } from "i18next";

const Login = () => {
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const handleFormikFormSubmit = async (values, actions) => {
    const { setFieldError } = actions;
    const payload = {
      data: {
        ...values,
        setFieldError,
      },
      url: "auth/login",
      setFieldError,
    };
    dispatch(LOGIN(payload));
  };

  return (
    <section className="bg-gray-100">
      <div className="max-w-md mx-auto">
        <div className="flex items-center justify-center h-screen ">
          <div className="w-full p-10 bg-white rounded shadow dark:bg-gray-900 h-100">
            <div className="mb-4 text-center">
              <h3 className="text-3xl font-bold mb-2">{t('Sign In')}</h3>
              <h2 className="text-2xl font-medium ">{'Celpip'}</h2>
            </div>
            {
              <Formik
                initialValues={initialValues}
                validationSchema={LoginValidationSchema}
                onSubmit={(values, actions) =>
                  handleFormikFormSubmit(values, actions)
                }
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="mb-6">
                        <FormikTextField
                          label="Email *"
                          name="email"
                          formik={formik}
                        />
                      </div>
                      <div className="mb-6">
                        <FormikTextField
                          label="Password *"
                          name="password"
                          type="password"
                          formik={formik}
                        />
                      </div>
                      {/* Submit */}
                      <div className="text-center ">
                        <button
                          className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                          type="submit"
                        >
                          {t('Login')}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            }
            <div className="mt-2 text-right">
              <Link
                to="/auth/forgot-password"
                className="text-sm text-gray-500 cursor-pointer hover:text-blue-700"
              >
                {t('Forgot Password')}

              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
