import React from 'react';

const QuestionsListWithInsert = ({ questions, insertQuestionId, handleEdit, navigateToAddNew, section, questionlabel }) => {

    return (
        <div className="p-6 bg-white rounded-md shadow dark:border-gray-800">
            <h3 className="text-lg font-medium mb-4">
                {questionlabel} {section}
            </h3>
            <button
                onClick={navigateToAddNew}
                className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:opacity-90"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mr-1 bi bi-plus-lg"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                </svg>
                Add Reading Question
            </button>
            <ul className="divide-y divide-gray-200">
                {questions?.length > 0 &&
                    questions.map((question, index) => (
                        <li className="p-3" key={index + question?.slug}>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold hidden">{question?._id}</span>
                                <span className="text-sm italic">{question?.slug}</span>
                                <div className="flex items-center gap-4">
                                    <button
                                        type="button"
                                        onClick={() => insertQuestionId(question?._id)}
                                        className="ml-4 text-xs text-gray-800 font-semibold hover:text-gray-800 focus:outline-none"
                                    >
                                        Insert
                                    </button>
                                    <button
                                        onClick={() => handleEdit(question._id)}
                                        className="font-medium text-primary hover:opacity-90"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="w-4 h-4 mr-2 bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                                fillRule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default QuestionsListWithInsert;
