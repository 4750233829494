import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APIServices from '../../../httpServices/httpServices';
import { useSelector } from 'react-redux';

const QuestionResponseDetail = ({ prepareQuestion, questions }) => {
    const { profile } = useSelector((state) => state.user);
    const [currentPart, setCurrentPart] = useState('1');
    const [responses, setResponses] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Updated responses:", responses);
    }, [responses]); // Log out responses when it changes

    useEffect(() => {
        if (questions.length > 0 && prepareQuestion.length > 0) {
            const initialResponses = {};
            prepareQuestion.forEach(pq => {
                const question = questions.find(q => pq._id === q._id);
                if (question && pq.Part === currentPart) {
                    const correctAnswer = findCorrectAnswer(question.Options);
                    initialResponses[question.Slug] = { answer: '', correctAnswer };
                }
            });
            setResponses(initialResponses);
        }
    }, [currentPart, prepareQuestion, questions]);

    const findCorrectAnswer = (options) => {
        console.log("optionsss", options)
        const correctOption = options.find(option => option.CorrectAnswer === true);
        return correctOption ? correctOption.Name : '';
    };

    const sendResponseToServer = async (responsesPayload) => {
        const questionSetId = prepareQuestion.find(pq => pq.Part === currentPart)?._id;
        const payload = {
            QuestionGroup: prepareQuestion[0].QuestionGroup,
            QuestionSetId: questionSetId,
            User: profile._id,
            Responses: responsesPayload,
            Submitted: currentPart === "4", // Mark as final submission if it's the last part
            ResponseType: currentPart === "4" ? "FinalSubmission" : "Partial",
        };

        try {
            const response = await new APIServices(`reading-response/`).post(payload);
            if (!response.success) {
                console.error("Failed to save response", response.error);
            }
        } catch (error) {
            console.error("Error saving response:", error);
        }
    };

    console.log("response", responses);

    const handleOptionChange = (questionSlug, answer) => {
        setResponses(prevResponses => {
            const updatedResponses = {
                ...prevResponses,
                [questionSlug]: {
                    ...prevResponses[questionSlug],
                    answer: answer
                }
            };

            return updatedResponses;
        });
    };

    const handleNextButtonClick = () => {
        // Send updated responses to the server
        const responsesPayload = Object.entries(responses).map(([questionSlug, { answer, correctAnswer }]) => ({
            Question: questionSlug,
            Answer: answer,
            CorrectAnswer: correctAnswer
        }));
        console.log("first", responsesPayload)
        sendResponseToServer(responsesPayload);

        // Move to the next part
        const nextPart = parseInt(currentPart) + 1;
        setCurrentPart(nextPart.toString());
    };

    const handleSubmit = async () => {
        // Final submission: send all responses
        const responsesPayload = Object.entries(responses).map(([questionSlug, { answer, correctAnswer }]) => ({
            Question: questionSlug,
            Answer: answer,
            CorrectAnswer: correctAnswer
        }));
        sendResponseToServer(responsesPayload);

        // Optionally, you can navigate to another page or perform other actions after submission
        // navigate('/thank-you-page'); // Example navigation
    };

    const renderDetail = (detailText, detailIndex) => {
        const regex = /\[(.*?)\]/g;
        const matches = detailText.match(regex);

        if (!matches) return <span dangerouslySetInnerHTML={{ __html: detailText }} />;

        return detailText.split(regex).map((item, index) => {
            if (matches.includes(`[${item}]`)) {
                const questionId = item;
                const question = questions.find(q => q._id === questionId);
                return question ? renderOptions(question, `${detailIndex}-${index}`) : `[${item}]`;
            } else {
                return <span key={index} dangerouslySetInnerHTML={{ __html: item }} />;
            }
        });
    };

    const renderOptions = (question, key) => {
        if (question.Type === "Dropdown") {
            return (
                <select key={key} onChange={(e) => handleOptionChange(question.Slug, e.target.value)}>
                    <option value=""></option>
                    {question.Options.filter(option => option.Name.trim() !== "").map((option, i) => (
                        <option key={i} value={option.Name}>{option.Name}</option>
                    ))}
                </select>
            );
        } else if (question.Type === "Radio") {
            return (
                <div key={key}>
                    {question.Options.filter(option => option.Name.trim() !== "").map((option, i) => (
                        <label key={i}>
                            <input
                                type="radio"
                                name={`radio_${key}`}
                                value={option.Name}
                                onChange={() => handleOptionChange(question.Slug, option.Name)}
                            />
                            {option.Name}
                        </label>
                    ))}
                </div>
            );
        }
    };

    const currentPrepareQuestion = prepareQuestion.filter(pq => pq.Part === currentPart);

    return (
        <div className="flex flex-col">
            {currentPrepareQuestion.map((pq, index) => (
                <div key={index} className="flex mb-4">
                    <div className="w-1/2">
                        <div className="p-6 bg-gray-50 text-sm text-gray-700 leading-loose" dangerouslySetInnerHTML={{ __html: pq.QuestionText }} />
                    </div>
                    <div className="w-1/2 bg-sky-100">
                        <div className="p-8">
                            {renderDetail(pq.Detail, index)}
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex justify-between mt-4">
                {parseInt(currentPart) < 4 && (
                    <button onClick={handleNextButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Next
                    </button>
                )}
                {parseInt(currentPart) === 4 && (
                    <button onClick={handleSubmit} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Submit
                    </button>
                )}
            </div>
        </div>
    );
};

export default QuestionResponseDetail;
