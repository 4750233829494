import React from "react";
import { useSelector } from "react-redux";
import SuperAdminLayout from "./SuperAdminLayout";
import UsersLayout from "./UsersLayout";

// This component decide layout according to user role
const LayoutDecider = () => {
  const { profile } = useSelector((state) => state.user);
  switch (profile?.role) {
    case "Superadmin":
    case "Admin":
      return <SuperAdminLayout />;
    default:
      return <UsersLayout />;
  }
};

export default LayoutDecider;
