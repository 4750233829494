import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextError } from "../../common/TextError";
import RoleValidationSchema from "../../../validation/RoleValidatationSchema";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
const RolesForm = ({ formData, id }) => {
  const navigate = useNavigate();
  const initial = {
    title: "",
    uniqueName: "",
    permissionValue: [],
    status: false,
  };
  const [initialValues, setInitialValues] = useState(initial);
  const [routeAndPermission, setRouteAndPermission] = useState(null);
  const [allPermissions, setAllPermissions] = useState([]);

  useEffect(() => {
    if (formData) {
      setInitialValues((prev) => ({ ...prev, ...formData }));
    }
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    (async () => {
      const { data, success } = await new APIServices(`permission/list`).get();
      if (success) {
        let allPer = [];
        setRouteAndPermission(data);
        data.forEach(
          (item) => (allPer = [...allPer, ...JSON.parse(item.permissionValue)])
        );
        setAllPermissions(allPer);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleFormikFormSubmit = async (values, actions) => {
    let { setSubmitting, resetForm } = actions;
    let payload = {
      ...values,
      status: values.status ? "Active" : "Inactive",
      permissionValue: values.permissionValue,
    };
    const { success, data, message } = !!!id
      ? await new APIServices(`role`).post(payload)
      : await new APIServices(`role/${id}`).put(payload);
    if (success) {
      emitSuccessToast(message);
      setSubmitting(false);
      resetForm();
      navigate(`/setting/role/view/${data._id}`, { replace: true });
    }
  };

  const handleSelectAll = (e, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue("permissionValue", allPermissions);
    } else {
      setFieldValue("permissionValue", []);
    }
  };

  const selectAllCheck = (allVal, value) => {
    const containsAll = allVal.length === value.length;
    return containsAll;
  };

  const handleAllCheck = (e, setFieldValue, allVal, value) => {
    if (e.target.checked) {
      setFieldValue("permissionValue", [...allVal, ...value]);
    } else {
      const filtered = allVal.filter((item) => !value.includes(item));
      setFieldValue("permissionValue", [...filtered]);
    }
  };

  const checkIfAllChecked = (allVal, value) => {
    const containsAll = value.every((element) => {
      return allVal.includes(element);
    });
    return containsAll;
  };
  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-4xl px-4 mx-auto ">
        <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
          <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">
            role's Information
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={RoleValidationSchema}
            onSubmit={handleFormikFormSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="mb-6">
                  <label
                    htmlFor="toggleB"
                    className="items-center block cursor-pointer"
                  >
                    <div className="mb-2">status</div>
                    <div className="relative flex">
                      <Field
                        type="checkbox"
                        id="toggleB"
                        name="status"
                        className="sr-only"
                      />
                      <div className="block h-8 bg-gray-200 rounded-full w-14"></div>
                      <div className="absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1"></div>
                    </div>
                  </label>
                </div>

                {/* title and uniqueName*/}
                <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium "
                      htmlFor=""
                    >
                      {t('title *')}
                    </label>
                    <Field type="text" name="title" placeholder="Enter a title">
                      {({ field, form, meta }) => {
                        return (
                          <input
                            name="title"
                            type="Text"
                            placeholder="Enter a title"
                            value={field.value || ""}
                            onChange={(e) => {
                              form.setFieldValue(
                                "uniqueName",
                                slugify(e.target.value, { lower: true })
                              );
                              form.setFieldValue(field.name, e.target.value);
                            }}
                            onBlur={form.handleBlur}
                            className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${formik.touched.title && formik.errors.title
                              ? "border-red-600"
                              : null
                              }`}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="title" component={TextError} />
                  </div>
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium "
                      htmlFor=""
                    >
                      uniqueName *
                    </label>
                    <Field
                      name="uniqueName"
                      type="text"
                      placeholder="Enter a unique name"
                      className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${formik.touched.uniqueName && formik.errors.uniqueName
                        ? "border-red-600"
                        : null
                        }`}
                    />
                    <ErrorMessage name="uniqueName" component={TextError} />
                  </div>
                </div>

                {/* Checkboxes  */}
                <div className="pt-5 -mx-6 border-t">
                  <div className="px-5">
                    <div>
                      <div className="flex">
                        <Field name="permissionValue">
                          {({ field, form, meta }) => {
                            return (
                              <>
                                <input
                                  name="permissionValue"
                                  type="checkbox"
                                  className="cursor-pointer"
                                  checked={selectAllCheck(
                                    allPermissions,
                                    form.values.permissionValue
                                  )}
                                  onChange={(e) =>
                                    handleSelectAll(e, form.setFieldValue)
                                  }
                                  onBlur={form.handleBlur}
                                />
                              </>
                            );
                          }}
                        </Field>
                        <label className="ml-2 text-sm font-bold">
                          Select All
                        </label>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-3">
                      {!!routeAndPermission &&
                        !!routeAndPermission.length &&
                        routeAndPermission.map((item) => {
                          return (
                            <div key={item.model}>
                              <div className="flex mt-2 mb-3">
                                <Field name="permissionValue">
                                  {({ field, form, meta }) => {
                                    return (
                                      <input
                                        name="permissionValue"
                                        type="checkbox"
                                        className="cursor-pointer"
                                        checked={checkIfAllChecked(
                                          form.values.permissionValue,
                                          JSON.parse(item.permissionValue)
                                        )}
                                        onChange={(e) =>
                                          handleAllCheck(
                                            e,
                                            form.setFieldValue,
                                            form.values.permissionValue,
                                            JSON.parse(item.permissionValue)
                                          )
                                        }
                                        onBlur={form.handleBlur}
                                      />
                                    );
                                  }}
                                </Field>
                                <label className="ml-2 text-sm font-bold">
                                  {item.model}
                                </label>
                              </div>

                              {!!item.permissionValue &&
                                JSON.parse(item.permissionValue).map((val) => {
                                  return (
                                    <div
                                      key={val}
                                      className="flex items-center mb-2 ml-5"
                                    >
                                      <Field
                                        type="checkbox"
                                        className="cursor-pointer"
                                        name="permissionValue"
                                        value={val}
                                      />
                                      <label className="ml-2 text-sm">
                                        {val}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                {/* Submit */}

                <div className="mt-7">
                  <div className="flex justify-start space-x-2">
                    <button
                      type="submit"
                      className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default RolesForm;
