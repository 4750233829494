import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextError } from "../../common/TextError";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import slugify from "slugify";
import FormikTextField from "../../common/form/FormikTextField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
// import FormikDateField from "../../common/form/FormikDateField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikNumberField from "../../common/form/FormikNumberField";
import FormikImageField from "../../common/form/FormikImageField";
import FormikRichTextEditor from "../../common/form/FormikRichTextEditor";
import { useNavigate } from "react-router-dom";
import PostValidationSchema from "../../../validation/PostValidationSchema";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import moment from "moment";
import FormikDateTimePickerField from "../../common/form/FormikDateTimePickerField";
import FormikCreatableSelect from "../../common/form/FormikCreatableSelect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SaveButton from "../../common/button/SaveButton";
import TinyPassageEditor from "../../common/richTextEditor/TinyPassageEditor";

const PostForm = ({ data: postData, id, pagetype }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pageTypeOptions, setPageTypeOptions] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [slugExist, setSlugExist] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [toggleSlug, setToggleSlug] = useState(false);
  const [tagListOptions, setTagListOptioins] = useState([]);
  const [categoryListOptions, setCategoryListOptioins] = useState([]);
  const [initialValues, setInitialValues] = useState({
    status: true,
    title: "",
    slug: "",
    pageType: pagetype,
    featuredImage: "",
    publishedOn: "",
    nextReviewDate: "",
    tags: [],
    categories: [],
    redirectTo: "",
    remark: "",
    isVerified: "",
    orderBy: "",
    summary: "",
    detail: "",
    seoTitle: "",
    seoDescription: "",
  });

  useEffect(() => {
    getFormSelectFieldValues();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!!id) {
      setToggleSlug(true);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (postData)
      setInitialValues((prev) => ({
        ...prev,
        ...postData,
      }));
    // eslint-disable-next-line
  }, [postData]);

  const handleFormikFormSubmit = async (values, actions) => {
    let bool = await validateSlug(values?.slug);
    if (!bool) {
      let oldTags = postData?.tags;
      let tagArrToRemove = [];
      let oldCategories = postData?.categories;
      let categoryArrToRemove = [];
      let { setSubmitting } = actions;

      let payload = {
        ...values,
        status: values.status ? "Active" : "Inactive",
        isVerified: values.isVerified ? "Yes" : "No",
        modifiedOn: moment().format(),
      };
      const { tags, categories, seoDescription, seoTitle, ...rest } = payload;
      tagArrToRemove = oldTags?.filter((item) => !tags.includes(item));
      categoryArrToRemove = oldCategories?.filter((item) => !categories.includes(item));
      let formData = new FormData();
      formData.append("tags", JSON.stringify([...new Set(tags)]));
      formData.append("categories", JSON.stringify([...new Set(categories)]));
      formData.append("seoTitle", seoTitle || payload.title);
      formData.append(
        "seoDescription",
        seoDescription || payload.summary || payload.title
      );

      Object.keys(rest).forEach((key, index) => {
        formData.append(key, rest[key]);
      });
      const { success, data, message } = !!!id
        ? await new APIServices(`post`).post(formData)
        : await new APIServices(`post/${id}`).put(formData);

      if (success) {
        setSubmitting(false);
        insertTags([...new Set(tags)], [...new Set(tagArrToRemove)]);
        insertCategories([...new Set(categories)], [...new Set(categoryArrToRemove)]);


        // if (!!id) {
        //   const { title, slug, detail, status, isVerified, ...rest } =
        //     initialValues;
        //   await new APIServices(`post-version`).post({
        //     title,
        //     slug,
        //     detail,
        //     LastChangedDate: moment(),
        //     ChangedBy: profile?._id,
        //     ExtraData: {
        //       ...rest,
        //       status: status === true ? "Active" : "Inactive",
        //       isVerified: isVerified === true ? "Yes" : "No",
        //     },
        //   });
        // }

        emitSuccessToast(message);
        navigate(`/post/view/${data.slug}`, { replace: true });
      }
    }
  };

  const getFormSelectFieldValues = async () => {
    const [pageTypeObj, tagObj, categoryObj] = await Promise.all([
      new APIServices("setting/get/page-type").get(),
      new APIServices("tag/list").post(),
      new APIServices("category/list").post(),
    ]);
    if (pageTypeObj.success) {
      const { settingValue } = pageTypeObj.data;
      let options = JSON.parse(settingValue)?.map((item) => ({
        value: item,
        label: t(item),
      }));
      setPageTypeOptions(options);
    }
    if (tagObj.success && !!tagObj?.data?.length) {
      let options = tagObj?.data?.map((item) => ({
        value: item.title,
        label: item.title,
      }));
      setTagListOptioins(options);
    }
    if (categoryObj.success && !!categoryObj?.data?.length) {
      let options = categoryObj?.data?.map((item) => ({
        value: item.title,
        label: item.title,
      }));
      setCategoryListOptioins(options);
    }
  };

  const insertTags = async (tagArr, tagArrToRemove = []) => {
    let tagTitleList = [];
    let tagToInsert = [];
    let alreadyHaveTag = [];
    const { data, success } = await new APIServices("tag/list").post();
    if (success) {
      tagTitleList = data.map((item) => item.title);
      tagArr.forEach((item) => {
        if (tagTitleList.includes(item)) {
          alreadyHaveTag.push(item);
        } else {
          tagToInsert.push(item);
        }
      });
    }

    if (tagToInsert.length) {
      const promises = tagToInsert.map(async (item) => {
        let payload = {
          status: "Inactive",
          title: item,
          slug: slugify(item, { lower: true }),
          Image: "",
          SpecialValue: "",
          TagType: null,
          detail: "",
          seoTitle: "",
          seoDescription: "",
          PostIds: [postData?._id],
        };
        return await new APIServices("tag").post(payload);
      });
      await Promise.all(promises);
    }

    if (alreadyHaveTag.length) {
      const promises = alreadyHaveTag.map(async (item) => {
        return await new APIServices("tag/postIds/add").post({
          tagTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
    if (tagArrToRemove.length) {
      const promises = tagArrToRemove.map(async (item) => {
        return await new APIServices("tag/postIds/remove").post({
          tagTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
  };

  const insertCategories = async (categoryArr, categoryArrToRemove = []) => {
    let categoryTitleList = [];
    let categoryToInsert = [];
    let alreadyHaveCategory = [];
    const { data, success } = await new APIServices("category/list").post();
    if (success) {
      categoryTitleList = data.map((item) => item.title);
      categoryArr.forEach((item) => {
        if (categoryTitleList.includes(item)) {
          alreadyHaveCategory.push(item);
        } else {
          categoryToInsert.push(item);
        }
      });
    }

    if (categoryToInsert.length) {
      const promises = categoryToInsert.map(async (item) => {
        let payload = {
          status: "Inactive",
          title: item,
          slug: slugify(item, { lower: true }),
          Image: "",
          SpecialValue: "",
          CategoryType: null,
          detail: "",
          seoTitle: "",
          seoDescription: "",
          PostIds: [postData?._id],
        };
        return await new APIServices("category").post(payload);
      });
      await Promise.all(promises);
    }

    if (alreadyHaveCategory.length) {
      const promises = alreadyHaveCategory.map(async (item) => {
        return await new APIServices("category/postIds/add").post({
          categoryTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
    if (categoryArrToRemove.length) {
      const promises = categoryArrToRemove.map(async (item) => {
        return await new APIServices("category/postIds/remove").post({
          categoryTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
  };

  const handleSelectMulti = (selectedOption, fieldName, setFieldValue) => {
    let val = selectedOption.map((opt) => {
      let str = opt.value;
      return str[0].toUpperCase() + str.slice(1);
    });
    setFieldValue(fieldName, val);
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
    setPageType(selectedOption.value);
  };

  // Async Validation
  const validateSlug = async (slug) => {
    let payload = {
      slug: slug,
      pageType: pageType,
    };
    const { success } = await new APIServices("post/check-slug-exist").post(
      payload
    );
    if (success) {
      setSlugExist(success);
      return true;
    } else {
      return false;
    }
  };
  const handleTitleChange = (e, field, form) => {
    if (slugExist) {
      setSlugExist(false);
    }
    form.setFieldValue(field.name, e.target.value);
    if (toggleSlug) {
      form.setFieldValue("slug", slugify(e.target.value, { lower: true }));
    }
  };

  const handleSlugChange = (e, field, form) => {
    if (slugExist) {
      setSlugExist(false);
    }
    form.setFieldValue("slug", slugify(e.target.value, { lower: true }));
  };

  const handleToggleSlug = () => {
    setToggleSlug(!toggleSlug);
  };

  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-4xl px-4 mx-auto ">
        <Formik
          initialValues={initialValues}
          validationSchema={PostValidationSchema}
          onSubmit={handleFormikFormSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <h2 className="mb-6 text-xl font-bold leading-6 text-gray-900 dark:text-gray-300">
                {t(pagetype)} {t("Details")}
              </h2>
              <div className="grid grid-cols-[70%,1fr] gap-4">
                <div>
                  <div className="p-6 mb-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                    {/* title  */}
                    <div className="mb-6">
                      <label
                        className="block mb-2 text-sm font-medium "
                        htmlFor=""
                      >
                        {t("Title")} *
                      </label>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Enter a title"
                      >
                        {({ field, form, meta }) => {
                          return (
                            <input
                              name="title"
                              type="Text"
                              placeholder="Enter a title"
                              value={field.value || ""}
                              onChange={(e) =>
                                handleTitleChange(e, field, form)
                              }
                              onFocus={(e) => {
                                setToggleSlug(true);
                              }}
                              onBlur={(e) => {
                                form.setTouched({
                                  ...form.touched,
                                  [field.name]: true,
                                });
                                form.handleBlur(e);
                                setToggleSlug(false);
                              }}
                              className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${formik.touched.title && formik.errors.title
                                ? "border-red-600"
                                : null
                                }`}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="title" component={TextError} />
                    </div>

                    <div className="mb-6">
                      <label
                        className="mb-2 text-sm font-medium  flex justify-between items-end"
                        htmlFor=""
                      >
                        {t("Slug")} *
                        <div>
                          <button
                            type="button"
                            onClick={handleToggleSlug}
                            className="inline-block px-2 py-2 bg-blue-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-600"
                          >
                            {t("Change slug")}
                          </button>
                        </div>
                      </label>

                      <Field
                        type="text"
                        name="slug"
                        placeholder={t("Enter a title")}
                      >
                        {({ field, form, meta }) => {
                          return (
                            <input
                              name="slug"
                              type="Text"
                              value={field.value || ""}
                              onChange={(e) => {
                                handleSlugChange(e, field, form);
                              }}
                              onBlur={(e) => {
                                form.handleBlur(e);
                              }}
                              readOnly={toggleSlug ? false : true}
                              className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded   dark:border-gray-800 dark:bg-gray-800 ${(formik.touched.slug || slugExist) &&
                                (formik.errors.slug || slugExist)
                                ? "border-red-600"
                                : null
                                }`}
                            />
                          );
                        }}
                      </Field>
                      {slugExist ? (
                        <div className="text-red-600 text-xs mt-2">
                          slug already exist.
                        </div>
                      ) : (
                        <ErrorMessage name="slug" component={TextError} />
                      )}
                    </div>
                    <div className="mb-6">
                      <FormikTextAreaField
                        label="Summary"
                        name="summary"
                        formik={formik}
                      />
                    </div>
                    <div className="mb-6">
                      {/* <FormikRichTextEditor label="detail" name="detail" /> */}
                      <label>Detail</label>
                      <TinyPassageEditor fieldname="detail" initialValue={formik.values.detail} setFieldValue={formik.setFieldValue} />
                    </div>
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <div className="mb-6">
                      <FormikTextField
                        label="Seo title"
                        name="seoTitle"
                        type="text"
                        formik={formik}
                      />
                    </div>
                    <div className="">
                      <FormikTextAreaField
                        label="Seo Description"
                        name="seoDescription"
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikSwitchField
                      label="status"
                      name="status"
                      formik={formik}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikSwitchField
                      label="Is Verified"
                      name="isVerified"
                      formik={formik}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    {!!!id && (
                      <div className="mb-4">
                        <FormikDateTimePickerField
                          label="Published On"
                          name="publishedOn"
                          formik={formik}
                        />
                      </div>
                    )}

                    {/* <div className="mb-4">
                      <FormikDateField
                        label="Next Review Date"
                        name="nextReviewDate"
                        formik={formik}
                        minDate={new Date()}
                      />
                    </div> */}
                    {id && (
                      <div className="mb-4">
                        <FormikSelectSingleField
                          label="Page Type *"
                          name="pageType"
                          options={pageTypeOptions}
                          handleSelectSingle={handleSelectSingle}
                        />
                      </div>
                    )}
                    <FormikNumberField
                      label="Order By"
                      name="orderBy"
                      formik={formik}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikImageField
                      label="Featured Image"
                      name="featuredImage"
                      formik={formik}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikCreatableSelect
                      label="Categories"
                      name="categories"
                      options={categoryListOptions}
                      handleSelectOption={handleSelectMulti}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    <FormikCreatableSelect
                      label="Tags"
                      name="tags"
                      options={tagListOptions}
                      handleSelectOption={handleSelectMulti}
                    />
                  </div>
                  <div className="p-6 mb-4 bg-white rounded-md shadow">
                    {/* <div className="mb-4">
                      <FormikTextField
                        type="text"
                        label="Redirect To"
                        name="redirectTo"
                        formik={formik}
                      />
                    </div> */}
                    <FormikTextAreaField
                      label="Remark"
                      name="remark"
                      formik={formik}
                    />
                  </div>
                </div>
              </div>

              {/* Submit */}
              <div className="mt-7">
                <div className="flex justify-start space-x-2">
                  <SaveButton isSubmitting={formik.isSubmitting} label={"Save"} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default PostForm;
