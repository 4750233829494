import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import { useTranslation } from "react-i18next";

const PostList = () => {
  const { pagetype } = useParams()

  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableHeading = ["title", "pageType", "status", "isVerified", "Action"];
  const [PostList, setPostList] = useState([]);
  // eslint-disable-next-line
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(0);
  // setLimit(LIMIT);
  const limit = LIMIT;

  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfPost();
      }, 1500);
    } else {
      getListOfPost();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage, pagetype]);

  const getListOfPost = async () => {
    const { data, success, total, totalPages } = await new APIServices(`post/getalllist/${pagetype}`).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    })

    if (success) {
      setIsLoading(false);
      setPostList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleDeletePost = async () => {
    const { message, success } = await new APIServices(
      `post/${selectedPostId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfPost();
    }
  };

  const handleEditPost = (id) => {
    navigate(`/post/edit/${id}`, {
      state: {
        pagetype: pagetype,
      }
    });
  }

  const handleView = (id) => {
    navigate(`/post/view/${id}`);
  };


  const navigateToAddNewPost = () => {
    navigate("/post/add",
      {
        state: {
          pagetype: pagetype,
        }
      }
    );
  }
  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title={`${t("List of")} ${t(pagetype)} `}
                navigate={navigateToAddNewPost}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={PostList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleEdit={handleEditPost}
                handleView={handleView}
                actionField="slug"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeletePost} close={setShowModal} />
      )}
    </section>
  );
};

export default PostList;
