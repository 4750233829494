import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
const CustomTable = ({
  tableHeadingData = [],
  tableData = [],
  handleDelete,
  handleEdit,
  handleAccept,
  handleReject,
  handleClick,
  categorySlug,
  handleChildCategory,
  handleChildCategoryView,
  handleEditSubCategory,
  handleView = undefined,
  handleReport = undefined,
  actionField,
  hasSelectAllFeature = false,
  handleSorting,
  sort,
  titleLink,
  handlePreviewQuestion,
  Id
}) => {
  const { t } = useTranslation();
  return (
    <div className="pt-4 overflow-x-auto">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-sm text-left text-gray-500 ">
            {tableHeadingData.map((item, index) => {
              if (index === 0 && hasSelectAllFeature) {
                return (
                  <th
                    className={`px-6 pb-3 font-medium   && flex items-center`}
                    key={uuid().slice(0, 8)}
                  >
                    {hasSelectAllFeature && (
                      <input className="mr-4" type="checkbox" name="" id="" />
                    )}
                    <span>
                      {tableHeadingData[0].replace(/([a-z](?=[A-Z]))/g, "$1 ")}
                    </span>
                  </th>
                );
              } else if (handleSorting) {
                return (
                  <th
                    className="px-6 pb-3 font-medium "
                    key={uuid().slice(0, 8)}
                  >
                    <div className="flex items-center">
                      <span>{item.replace(/([a-z](?=[A-Z]))/g, "$1 ")}</span>
                      <span onClick={() => handleSorting(item)}>
                        {item in sort ? (
                          sort?.[item] === 1 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill w-2.5 ml-2 h-2.5"
                              viewBox="0 0 16 16"
                            >
                              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill w-2.5 ml-2 h-2.5"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                          )
                        ) : (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill w-2.5 ml-2 h-2.5"
                              viewBox="0 0 16 16"
                            >
                              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill w-2.5 ml-2 h-2.5"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                          </span>
                        )}
                      </span>
                    </div>
                  </th>
                );
              } else {
                return (
                  <th
                    className="px-6 pb-3 font-medium "
                    key={uuid().slice(0, 8)}
                  >
                    {t(item.replace(/([a-z](?=[A-Z]))/g, "$1 "))}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {!!tableData.length ? (
            tableData.map((item, i) => {
              return (
                <tr
                  className={`${i % 2 === 0 ? " bg-white" : "bg-white"
                    } text-sm   border-t`}
                  key={uuid().slice(0, 8)}
                >
                  {tableHeadingData.map((heading, index) => {
                    if (index === 0 && hasSelectAllFeature) {
                      return (
                        <td
                          className="flex items-center px-6 py-5 font-medium"
                          key={uuid().slice(0, 8)}
                        >
                          <input
                            className="mr-4"
                            type="checkbox"
                            name=""
                            id=""
                          />
                          <p className="">{item[heading] || "--"}</p>
                        </td>
                      );
                    } else if (heading === "status") {
                      return (
                        <td
                          className="px-6 py-5 font-medium "
                          key={uuid().slice(0, 8)}
                        >
                          <span className="flex items-center text-sm">
                            <div
                              className={`rounded-full w-2 h-2 mr-1 ${item[heading] === "Active"
                                ? "bg-green-500"
                                : "bg-red-500"
                                }`}
                            ></div>
                            {t(item[heading]) || "--"}
                          </span>
                        </td>
                      );
                    }
                    else if (heading === "RequestedDate") {
                      return (
                        <td
                          className="px-6 py-5 font-medium "
                          key={uuid().slice(0, 8)}
                        >
                          <span className="flex items-center text-sm">
                            {moment(item?.Date).format("YYYY-MM-DD")}
                          </span>
                        </td>
                      );
                    }
                    else if (heading === "Child" && heading !== "Children") {
                      if (item[heading].length > 0) {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            <button
                              onClick={() => handleView(item[actionField])}
                              className=" flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                            >
                              {t('View Sub Categories')}
                              <div className="flex items-center justify-center w-6 h-6 ml-2 mr-2 bg-red-400 rounded-full ">
                                <p>
                                  {(item[heading]).length}
                                </p>
                              </div>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            {t('No Sub Categories')}
                          </td>
                        );
                      }
                    }
                    else if (heading === "Children") {
                      const modifiedHeading = "Child"
                      if (item[modifiedHeading].length > 0) {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            <button
                              onClick={() => handleChildCategoryView(categorySlug, item[actionField])}
                              className=" flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                            >

                              {t('View Child Categories')}
                              <div className="flex items-center justify-center w-6 h-6 ml-2 mr-2 bg-red-400 rounded-full ">
                                <p>
                                  {(item[modifiedHeading]).length}
                                </p>
                              </div>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >

                            {t('No Child Categories')}

                          </td>
                        );
                      }
                    } else if (heading === "Action") {
                      return (
                        <td className="px-6 py-5 " key={uuid().slice(0, 8)}>
                          <div className="flex items-center w-full">
                            {handlePreviewQuestion && (
                              <button
                                onClick={() => handlePreviewQuestion(item[actionField], item.title)}
                                className=" flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mr-4"
                              >
                                Prepare Questions
                              </button>
                            )}
                            {handleView && (
                              <button
                                onClick={() => handleView(item[actionField])}
                              >

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 mr-2 bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                              </button>
                            )}
                            {handleChildCategoryView && (
                              <button
                                onClick={() => handleChildCategoryView(categorySlug, item[actionField])}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 mr-2 bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                              </button>
                            )}
                            {handleEdit && (
                              <button
                                onClick={() => handleEdit(item[actionField])}
                                className="font-medium text-primary hover:opacity-90"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 mr-2 bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </button>
                            )}
                            {((item.Type === "Radio") || (item.Type === "Checkbox") || (item.Type === "Switch") || (item.Type === "Dropdown")) && handleReport && (
                              <button
                                onClick={() => handleReport(item.QuestionGroup._id, item[actionField])}
                                className="flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                              >
                                Report
                              </button>
                            )}
                            {handleEditSubCategory && (
                              <button
                                onClick={() => handleEditSubCategory(categorySlug, item[actionField])}
                                className="font-medium text-primary hover:opacity-90"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-4 h-4 mr-2 bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </button>
                            )}

                            {handleAccept && (
                              <button
                                onClick={() => handleAccept(item[actionField])}
                                className="flex items-center px-4 py-2.5 mr-2 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                              >
                                {t('Approve')}
                              </button>
                            )}
                            {handleReject && (
                              <button
                                onClick={() => handleReject(item[actionField])}
                                className="flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
                              >
                                {t('Reject')}
                              </button>
                            )}
                            {handleClick && (
                              <button
                                onClick={() => handleClick(item[actionField])}
                                className=" flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                              >
                                {t('Duplicate')}
                              </button>
                            )}

                            {handleChildCategory && (
                              <button
                                onClick={() => handleChildCategory(categorySlug, item[actionField])}
                                className=" flex items-center px-4 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                              >
                                {t('Add Child Category')}
                              </button>
                            )}

                            {handleDelete && (
                              <button
                                onClick={() => handleDelete(item[actionField])}
                                className="font-medium text-red-600 hover:text-red-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-trash3"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </td>
                      );
                    } else if (heading === "Date") {
                      return (
                        <td
                          className="px-6 py-5 font-medium"
                          key={uuid().slice(0, 8)}
                        >
                          {moment(item?.Date).format("YYYY/MM/DD")}
                        </td>
                      );
                    } else if (
                      ["PermissionValue", "SettingValue"].includes(heading)
                    ) {
                      if (!!item[heading]) {
                        return (
                          <td
                            className="flex flex-wrap items-center gap-2 px-6 py-5 font-medium text-blue-700"
                            key={uuid().slice(0, 8)}
                          >
                            {JSON.parse(item[heading])
                              .slice(0, 5)
                              .map((val) => {
                                return (
                                  <span
                                    key={uuid().slice(0, 8)}
                                    className="inline-block px-2 py-1 text-xs bg-blue-100 rounded "
                                  >
                                    {val}
                                  </span>
                                );
                              })}
                            {JSON.parse(item[heading]).length > 5 && "....."}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            {"--"}
                          </td>
                        );
                      }
                    } else if (["FocusWord"].includes(heading)) {
                      if (!!item[heading]) {
                        return (
                          <td
                            className="flex flex-wrap items-center gap-2 px-6 py-5 font-medium"
                            key={uuid().slice(0, 8)}
                          >
                            {item[heading].map((val) => {
                              return (
                                <span
                                  key={uuid().slice(0, 8)}
                                  className="inline-block px-2 py-1 text-xs text-blue-700 bg-blue-100 rounded "
                                >
                                  {val}
                                </span>
                              );
                            })}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            {"--"}
                          </td>
                        );
                      }
                    } else if (heading === "Title") {
                      if (titleLink)
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            <Link
                              to={`/${titleLink}/${item?._id}`}
                              className="text-primary font-small hover:underline"
                            >
                              {item[heading]?.toString() || "--"}
                            </Link>
                          </td>
                        );
                      else {
                        return (
                          <td
                            className="px-6 py-5 font-medium "
                            key={uuid().slice(0, 8)}
                          >
                            {item[heading]?.toString() || "--"}{" "}
                          </td>
                        );
                      }
                    } else if (heading === "HouseModel") {
                      return (
                        <td
                          className="px-6 py-5 font-medium"
                          key={uuid().slice(0, 8)}
                        >
                          {item?.InterventionType?.HouseModel}
                        </td>
                      );
                    } else if (heading === "DamageType") {
                      return (
                        <td
                          className="px-6 py-5 font-medium"
                          key={uuid().slice(0, 8)}
                        >
                          {(item?.InterventionType?.DamageType).join(',')}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className="px-6 py-5 font-medium "
                          key={uuid().slice(0, 8)}
                        >
                          {item[heading]?.toString() || "--"}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={tableHeadingData.length}
                className="py-12 text-3xl text-center text-gray-500"
              >
                {t('No Data Found')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
